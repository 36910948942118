<template>
	<safeguard-table-with-filter
		report-link="reports/accidents-and-work-hours"
		:table-headers="[
			{ key: 'project_id' },
			{ key: 'created_at', label: 'injury date' },
			{ key: 'injury_type' },
			{ key: 'work_description' },
			{ key: 'injury_details' },
			{ key: 'action_taken' },
			{ key: 'corrective_actions' },

			{ key: 'user.name', label: 'name' },
		]"
	/>
</template>

<script>
import SafeguardTableWithFilter from '../components/SafeguardTableWithFilter.vue';

export default {
	name: 'LocationVisits',
	components: { SafeguardTableWithFilter },
};
</script>
