var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('safeguard-table-with-filter',{attrs:{"report-link":"reports/accidents-and-work-hours","table-headers":[
		{ key: 'project_id' },
		{ key: 'created_at', label: 'injury date' },
		{ key: 'injury_type' },
		{ key: 'work_description' },
		{ key: 'injury_details' },
		{ key: 'action_taken' },
		{ key: 'corrective_actions' },

		{ key: 'user.name', label: 'name' } ]}})}
var staticRenderFns = []

export { render, staticRenderFns }